<template>
  <div class="sale-activity-record">
    <PageTitle title="銷售回饋活動設定" btn="新增" @btnClick="onCreate" />
    <FiltersContainer>
      <BaseElInput
        v-model="search.name"
        clearable
        placeholder="輸入活動名稱"
        @clear="refresh(true)"
        @keypress.enter.native="refresh(true)"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search"
          @click="refresh(true)"
        />
      </BaseElInput>
      <BaseElSelect
        v-model="search.enabled"
        clearable
        placeholder="選擇活動狀態"
        @change="refresh(true)"
        @clear="refresh(true)"
      >
        <BaseElSelectOption
          v-for="status in feedbackActivityStatusConfig"
          :key="status.value"
          :label="status.label"
          :value="status.value"
        />
      </BaseElSelect>
    </FiltersContainer>
    <SalesActivityRecordTable v-loading="loading.table" :tableData="tableData" @refresh="refresh(false)" />
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh(false)"
    />
  </div>
</template>

<script>
import { defineComponent, onActivated, onMounted } from 'vue'
import { useRouter } from 'vue-router/composables'
import { useTable } from '@/use/table'
import PageTitle from '@/components/Title/PageTitle.vue'
import FiltersContainer from '@/components/Container/FiltersContainer.vue'
import SalesActivityRecordTable from './components/SalesActivityRecordTable.vue'
import { feedbackActivityStatusConfig } from '@/config/memberShop'
import { GetRewardActivity, GetRewardActivityCount } from '@/api/rewardActivity'

export default defineComponent({
  name: 'SalesActivityRecord',
  components: { PageTitle, FiltersContainer, SalesActivityRecordTable },
  setup (props) {
    const router = useRouter()
    const {
      extendData,
      search,
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      pageStartIndex,
      shopId,
      fetchData,
      fetchDataCount,
    } = useTable()

    extendData('search', {
      name: null,
      status: null,
    })

    const onCreate = () => {
      router.push({ name: 'SalesActivityEdit' })
    }

    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        name: search.name || undefined,
        enabled: search.enabled || undefined,
        source: 'SALE_RECORD',
      }
      await Promise.allSettled([
        fetchData(GetRewardActivity, payload),
        fetchDataCount(GetRewardActivityCount, payload),
      ])
    }

    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      loading.table = true
      await getTableData()
      loading.table = false
    }

    onMounted(() => {
      refresh()
    })
    onActivated(() => {
      refresh()
    })
    return {
      search,
      loading,
      tableData,
      tableOptions,
      tableDataCount,
      refresh,
      onCreate,
      feedbackActivityStatusConfig,
    }
  },
})
</script>
